import { postRequest, getRequest, deleteRequest } from '../utils/request'


export default {
    changeTimeInfo(status, params,typeStore,soeId){
        return postRequest(`/api/timeCard/${status}?typeStore=${typeStore}&soeId=${soeId}`, params)
    },
    //会员列表
    list(params) {
        const {params:para}=params
        return getRequest('/api/timeCard/list',para)
    },
    getInfoByCouId(id) {//根据id查询详情
        return getRequest(`/api/timeCard/info/${id}`)
    },
    getTimeCardLink(couId){
        return postRequest(`/api/timeCard/getTimeCardLink/${couId}`)
    },
    soldOutCardById(timId) {
        return postRequest(`/api/timeCard/soldOutCardById/${timId}`)
    },
    //merchants,store,params
    getStatVoucherList(current,merchants,store,params,oneCouBatch,size){
        let startDate = "";
        let endDate = "";
        let cardName = "";
        let alId = 0;
        if(params.offcUseDm != undefined){
            startDate = params.offcUseDm[0];
            endDate = params.offcUseDm[1];
        }
        if(params.cardName != undefined){
            cardName = params.cardName
        }
        if(params.alId != undefined){
            alId = params.alId
        }
        console.log(alId);
        return getRequest(`/api/timeCard/getStatVoucherList?current=${current}&merchants=${merchants}&store=${store}&alId=${alId}&cardName=${cardName}&startDate=${startDate}&endDate=${endDate}&couBatch=${oneCouBatch}&size=${size}`)
    },
    getStatTimeCard(current,merchants,store,params,oneCouBatch,size){
        let startDate = "";
        let endDate = "";
        let cardName = "";
        let alId = 0;
        if(params.offcUseDm != undefined){
            startDate = params.offcUseDm[0];
            endDate = params.offcUseDm[1];
        }
        if(params.cardName != undefined){
            cardName = params.cardName
        }
        if(params.alId != undefined){
            alId = params.alId
        }
        return getRequest(`/api/timeCard/getStatTimeCard?current=${current}&merchants=${merchants}&store=${store}&alId=${alId}&cardName=${cardName}&startDate=${startDate}&endDate=${endDate}&couBatch=${oneCouBatch}&size=${size}`)
    },
    getCardDatails(couBatch,current,size,params){
        let startDate = "";
        let endDate = "";
        if(params.offcUseDm != undefined){
            startDate = params.offcUseDm[0];
            endDate = params.offcUseDm[1];
        }
        return getRequest(`/api/timeCard/getCardDatails?couBatch=${couBatch}&current=${current}&startDate=${startDate}&endDate=${endDate}&size=${size}`)
    },
    getStatVouchDatails(couBatch,current,size,params){
        let startDate = "";
        let endDate = "";
        if(params.offcUseDm != undefined){
            startDate = params.offcUseDm[0];
            endDate = params.offcUseDm[1];
        }
        return getRequest(`/api/timeCard/getStatVouchDatails?couBatch=${couBatch}&current=${current}&startDate=${startDate}&endDate=${endDate}&size=${size}`)
    }

}
